
import { defineComponent } from 'vue'
import { modifyPassword } from '@/api/base'
export default defineComponent({
    name: 'CodeChange',
    data () {
        return {
            oldCode: '',
            newCode: '',
            confirmCode: '',
            isConfirmCode: false
        }
    },

    methods: {
        async onModifyCode () {
            if (!this.oldCode) {
                this.$message.error('请输入原密码')
                return
            }
            if (this.newCode.length < 6) {
                this.$message.error('新密码至少输入六位')
                return
            }
            if (this.isConfirmCode) {
                await modifyPassword({
                    newPassword: this.newCode,
                    oldPassword: this.oldCode
                })
                this.$message.success('密码修改成功')
                this.$router.push({
                    path: '/'
                })
            }
        },
        blurConfirmCode () {
            if (this.confirmCode !== this.newCode) {
                this.$message.error('请确认两次密码是否同样')
                this.isConfirmCode = false
            } else if (this.newCode.length < 6) {
                this.isConfirmCode = false
            } else {
                this.isConfirmCode = true
            }
        },
        blurNewCode () {
            // if (this.newCode.length < 6) {
            //     this.$message.error('请至少输入六位')
            //     this.isConfirmCode = false
            // } else {
            //     this.isConfirmCode = true
            // }
        }
    }
})
